import React from 'react';
import { ScrollView, View, Text, StyleSheet, Image } from 'react-native';

import {
  GTAmericaCompressed,
  greenBrand,
  blackBrand,
  TrailersDemiBold,
} from '../libs/constants';
import { CuratorHeader } from '../components/curator/CuratorHeader';
import { PhoneNumberSignupForm } from '../components/forms/PhoneNumberSignupForm';
import { EventSlice } from '../components/events/EventSlice';

interface Props {}

export const EventsLandingScreen: React.FC<Props> = () => {
  const measuringTape = require('@happymedium/components/assets/images/blue-white-measure-tape-long-horizontal.png');
  const styles = StyleSheet.create({
    infoText: {
      fontFamily: GTAmericaCompressed,
      fontWeight: '300',
      fontSize: 24,
      color: blackBrand,
    },
  });
  return (
    <ScrollView
      style={{
        flex: 1,
        backgroundColor: greenBrand,
      }}
    >
      <CuratorHeader
        backgroundColor={greenBrand}
        hideThemePicker={true}
        logoColor={'black'}
        linkColor={blackBrand}
        isRow={true}
      />
      <View style={{ height: 20 }} />
      <View
        style={{
          backgroundColor: blackBrand,
          paddingTop: 20,
          paddingBottom: 30,
          width: '100vw',
          maxWidth: 584,
          alignSelf: 'center',
        }}
      >
        <Text
          style={[
            styles.infoText,
            {
              fontSize: 22,
              fontFamily: TrailersDemiBold,
              textTransform: 'uppercase',
              letterSpacing: 1.4,
              textAlign: 'center',
              color: greenBrand,
            },
          ]}
        >
          Sign up to be the first to know about new events
        </Text>
        <View style={{ alignItems: 'center', marginTop: 5 }}>
          <PhoneNumberSignupForm />
        </View>
      </View>
      <View style={{ width: 30, height: 60 }} />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="September 23rd, 2021"
        eventTime="6:00pm - 8:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/9.23rec.jpg"
        eventSubtitle="with special guest, Nellie Blue."
        eventUrl="/event/8"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/14k2bO7KY4224Mw6oC"
      />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="September 16th, 2021"
        eventTime="6:00pm - 8:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/9.16rec.jpg"
        eventSubtitle="with special guest, Nellie Blue."
        eventUrl="/event/7"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/fZe4jW3uI5664Mw5kx"
      />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="September 9th, 2021"
        eventTime="6:00pm - 8:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/9.9+rec.jpg"
        eventSubtitle="with special guest, Nellie Blue."
        eventUrl="/event/6"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/9AQ4jWghu5664MweV6"
      />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="August 31st, 2021"
        eventTime="6:30pm - 8:30pm"
        eventImgUrl="https://49market.s3.amazonaws.com/8.31+poster.jpg"
        eventSubtitle="in collaboration with artists, Alex Schmidt & Lauren Gerrie."
        eventUrl="/event/5"
        soldOut={true}
        ticketUrl="https://buy.stripe.com/fZe2bO6GU2XY3Is4gl"
      />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="August 20th, 2021"
        eventTime="6:00pm - 8:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/Nellie20th.png"
        eventSubtitle="with special guest, Nellie Blue."
        eventUrl="/event/4"
        soldOut={true}
        ticketUrl="https://buy.stripe.com/dR6bMo8P2566a6Q147"
      />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="August 7th, 2021"
        eventTime="5:00pm - 7:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/HMandLG-FruitsOfOurLabor.jpg"
        eventSubtitle="in collaboration with artist, Lauren Gerrie."
        eventUrl="/event/3"
        soldOut={true}
        ticketUrl="https://buy.stripe.com/7sI17K1mA8ii6UEbIK"
      />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="August 5th, 2021"
        eventTime="6:00pm - 8:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/HMandNB-GeorgiaOKeefe.png"
        eventSubtitle="with special guest, Nellie Blue."
        eventUrl="/event/2"
        soldOut={true}
        ticketUrl="https://buy.stripe.com/8wMbMo5CQdCC2EoeUV"
      />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="July 30th, 2021"
        eventTime="6:00pm - 8:00pm"
        eventImgUrl="https://thesalonnewsletter.s3.us-east-2.amazonaws.com/011/Stripo+Resized.jpg"
        eventSubtitle="with special guest, Nellie Blue."
        eventUrl="/event/1"
        soldOut={true}
        ticketUrl=""
      />
      <View style={{ height: 40 }} />

      <Image
        source={measuringTape}
        style={{
          height: 45,
          width: 450,
          maxWidth: '100vw',
          resizeMode: 'contain',
          marginTop: 40,
          paddingBottom: 40,
          alignSelf: 'center',
          transform: [{ rotate: '-10deg' }],
          left: 200,
        }}
      />
    </ScrollView>
  );
};
