import React, { useState } from 'react';
import {
  View,
  StyleSheet,
  Text,
  ActivityIndicator,
  TextInput,
  Image,
  Linking,
} from 'react-native';
import { useMutation } from '@apollo/react-hooks';

import { UpdateSubmissionDocument } from '../ApolloComponents';
import { useThemeState } from '../../context/ThemeContext';
import { PanamaBold, GTAmerica } from '../../libs/constants';
import { Formik } from 'formik';
import { TouchableOpacity } from '../common/TouchableOpacity';
import { RadioButton } from '../common/RadioButton';

interface Props {
  id: string;
  setFormSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
}

interface FormValues {
  title?: string;
  artistName?: string;
  artistInstaHandle?: string;
  released?: boolean;
}

export const UpdateArtSubmissionForm: React.FC<Props> = ({
  id,
  setFormSubmitted,
}) => {
  const { theme } = useThemeState();
  const [updateSubmission, { data }] = useMutation(UpdateSubmissionDocument);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hideForm, setHideForm] = useState(false);
  const [radioRelease, setRadioRelease] = useState(true);
  console.log('FORM_ID', id);

  const styles = StyleSheet.create({
    container: { flex: 1 },
    input: {
      // minWidth: '50%',
      // maxWidth: '90%',
      width: '100%',
      backgroundColor: theme.backgroundColor,
      fontFamily: PanamaBold,
      height: 29,
      fontSize: 18,
      letterSpacing: 0.9,
      marginVertical: 15,
      paddingLeft: 2,
      color: theme.foregroundColor,
      placeholderTextColor: theme.foregroundColor, // TODOD: throwing an error?
      flexGrow: 1,
      borderBottomWidth: 1,
      borderBottomColor: theme.foregroundColor,
    },
    form: {
      // marginTop: 20,
      marginBottom: 20,
      marginHorizontal: 0,
      paddingHorizontal: 25,
      width: '90%',
      flexGrow: 1,
      maxWidth: 500,
      flexDirection: 'column',
      // flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    label: {
      fontFamily: GTAmerica,
      fontWeight: '400',
      letterSpacing: 0.9,
      fontSize: 18,
      paddingLeft: '10%',
      alignSelf: 'center',
      width: '100%',
      height: '100%',
    },
    successMessage: {
      textAlign: 'center',
      fontFamily: GTAmerica,
      color: theme.foregroundColor,
      fontSize: 20,
    },
  });

  const initialValues: FormValues = {
    title: '',
    artistName: '',
    artistInstaHandle: '',
    released: radioRelease,
  };

  const [insta, setInsta] = useState<string | null>(null);

  return (
    <View style={styles.container}>
      <Formik<FormValues>
        initialValues={initialValues}
        onSubmit={async (values, { resetForm }) => {
          setDisabled(true);
          setLoading(true);
          const result = await updateSubmission({
            variables: {
              input: {
                id,
                title: values.title,
                artistName: values.artistName,
                artistInstaHandle: values.artistInstaHandle,
                released: radioRelease,
              },
            },
          });
          if (result.data.updateSubmission.success === true) {
            setHideForm(true);
            setFormSubmitted(true);
            setDisabled(false);
            setLoading(false);
            resetForm({ values: initialValues });
          }
          if (result.data.subscribe.success === false) {
            setLoading(false);
            setDisabled(false);
            resetForm();
            console.log('no joy');
          }
        }}
      >
        {({ handleBlur, handleChange, handleSubmit, values }) => (
          <View collapsable={false} style={{ width: '100%' }}>
            {hideForm ? (
              <View
                style={{
                  width: '100%',
                  // height: 700,
                  alignItems: 'center',
                  alignContent: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  paddingHorizontal: 25,
                  marginTop: 20,
                }}
              >
                <Text style={styles.successMessage}>Thanks for sharing!</Text>
                <Text style={styles.successMessage}>
                  Check out your work in the{' '}
                  <Text
                    onPress={() => Linking.openURL('/gallery')}
                    style={[
                      styles.successMessage,
                      {
                        textDecorationLine: 'underline',
                      },
                    ]}
                  >
                    gallery.
                  </Text>
                </Text>
              </View>
            ) : (
              <View style={{ alignItems: 'center', width: '100%' }}>
                <Text
                  style={{
                    textAlign: 'center',
                    fontFamily: GTAmerica,
                    fontWeight: '300',
                    fontSize: 22,
                    letterSpacing: 1.1,
                    color: theme.foregroundColor,
                    marginTop: 20,
                    marginBottom: 5,
                  }}
                >
                  Name It, Claim It
                </Text>

                <View style={styles.form}>
                  {/* <View style={styles.form}> */}
                  <TextInput
                    onChangeText={handleChange('title')}
                    onBlur={handleBlur('title')}
                    value={values.title}
                    spellCheck={false}
                    style={[styles.input, {}]}
                    placeholder="Title (optional)"
                  />
                  {/* </View> */}
                  <TextInput
                    onChangeText={handleChange('artistName')}
                    onBlur={handleBlur('artistName')}
                    value={values.artistName}
                    spellCheck={false}
                    style={[styles.input, {}]}
                    placeholder="Artist (optional)"
                  />
                  <TextInput
                    onChangeText={handleChange('artistInstaHandle')}
                    onBlur={() => {
                      handleBlur('artistInstaHandle');
                      if (values.artistInstaHandle === '@') {
                        values.artistInstaHandle = '';
                        setInsta('');
                      }
                    }}
                    value={values.artistInstaHandle}
                    spellCheck={false}
                    selectTextOnFocus={false}
                    onFocus={() => {
                      values.artistInstaHandle = '@';
                      setInsta('@');
                      handleChange('artistInstaHandle');
                    }}
                    style={[styles.input, {}]}
                    placeholder="IG Handle (optional)"
                  />
                  <Text
                    style={{
                      color: theme.foregroundColor,
                      fontSize: 16,
                      letterSpacing: 0.9,
                      fontFamily: GTAmerica,
                      fontWeight: '300',
                    }}
                  >
                    We love your art - can we use it for future projects? (IG
                    posts, Art Zine, etc.)
                  </Text>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-evenly',
                      width: '100%',
                    }}
                  >
                    <RadioButton
                      defaultValue={false}
                      analyticsLabel={'sure'}
                      label="sure"
                      labelStyle={styles.label}
                      checked={radioRelease === true}
                      onChange={(value) => {
                        setRadioRelease(true);
                      }}
                    />
                    <RadioButton
                      defaultValue={true}
                      analyticsLabel={'no'}
                      label="no"
                      labelStyle={styles.label}
                      checked={radioRelease === false}
                      onChange={(value) => {
                        setRadioRelease(false);
                      }}
                    />
                  </View>
                  {loading ? <ActivityIndicator /> : null}
                  <TouchableOpacity
                    // loading={loading}
                    disabled={disabled}
                    style={{ width: '100%' }}
                    onPress={() => {
                      // console.log('value', values.email);
                      // if (values.email === null || values.email === '') {
                      //   setDisplayError(true);
                      //   return;
                      // }
                      handleSubmit();
                    }}
                  >
                    <View
                      style={{
                        width: '100%',
                        borderColor: theme.foregroundColor,
                        borderWidth: 1,
                        height: 50,
                        alignContent: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Text
                        style={{
                          fontWeight: '400',
                          fontFamily: GTAmerica,
                          letterSpacing: 1.1,
                          fontSize: 22,
                          color: theme.foregroundColor,
                        }}
                      >
                        Save
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
            )}
          </View>
        )}
      </Formik>
    </View>
  );
};
