import React from 'react';
import {
  Image,
  ImageSourcePropType,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { Hoverable } from 'react-native-web-hooks';

import { lgBreakpoint } from '../../constants';
import { useDimensions } from '../../context/DimensionsContext';
import {
  blackBrand,
  greenBrand,
  GTAmerica,
  GTAmericaCompressed,
  PanamaBold,
  redBrand,
  wineBrand,
} from '../../libs/constants';
import { HoverableLink } from '../common/HoverableLink';

interface Props {
  eventTitle: string;
  eventSubtitle: string;
  eventTime: string;
  eventDate: string;
  eventUrl: string;
  eventImgUrl: string;
  ticketUrl: string;
  soldOut?: boolean;
}

export const EventSlice: React.FC<Props> = ({
  eventImgUrl,
  eventTitle,
  eventUrl,
  soldOut,
  eventSubtitle,
  eventDate,
  eventTime,
  ticketUrl,
}) => {
  const { width } = useDimensions();
  const styles = StyleSheet.create({
    infoText: {
      fontFamily: GTAmericaCompressed,
      fontWeight: '300',
      fontSize: 24,
      color: blackBrand,
    },
  });
  return (
    <View
      style={{
        flex: 1,
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        backgroundColor: greenBrand,
        flexDirection: width > lgBreakpoint ? 'row' : 'column',
        paddingTop: width < lgBreakpoint ? 0 : 30,
        marginVertical: width < lgBreakpoint ? 25 : 0,
      }}
    >
      <View>
        <Image
          source={eventImgUrl as ImageSourcePropType}
          style={{
            height: 359,
            width: 250,
            maxWidth: '100vw',
            marginHorizontal: 10,
            resizeMode: 'contain',
          }}
        />
      </View>
      <View style={{ width: 40, height: 10 }} />
      <View
        style={{
          alignItems: width < lgBreakpoint ? 'center' : 'flex-start',
          marginTop: 20,
        }}
      >
        <View
          style={{
            flexDirection: 'column',
            width: 300,
            alignItems: width < lgBreakpoint ? 'center' : 'flex-start',
          }}
        >
          <Text
            style={{
              fontFamily: PanamaBold,
              fontSize: 24,
              color: blackBrand,
              textTransform: 'uppercase',
            }}
          >
            {eventTitle}
          </Text>
          <Text
            style={[
              styles.infoText,
              { fontWeight: '500', fontSize: width < lgBreakpoint ? 20 : 24 },
            ]}
          >
            {eventSubtitle}
          </Text>
          <Text style={[styles.infoText, { fontFamily: GTAmerica }]}>
            {eventDate}
          </Text>
          <Text
            style={[
              styles.infoText,
              { textTransform: 'uppercase', fontSize: 18 },
            ]}
          >
            {eventTime}
          </Text>
          {soldOut ? (
            <View
              style={{
                backgroundColor: redBrand,
                opacity: 0.8,
                paddingVertical: 5,
                alignItems: 'center',
                marginTop: 5,
                width: 250,
              }}
            >
              <Text
                style={[
                  styles.infoText,
                  {
                    textTransform: 'uppercase',
                    fontSize: 24,
                    fontWeight: '700',
                    color: wineBrand,
                  },
                ]}
              >
                Sold Out
              </Text>
            </View>
          ) : (
            <HoverableLink linkType="web" linkUrl={ticketUrl}>
              <Hoverable>
                {(isHovered) => (
                  <View
                    style={{
                      backgroundColor: redBrand,
                      paddingVertical: 5,
                      alignItems: 'center',
                      marginTop: 5,
                      // width: '100%',
                      width: 250,
                      transform: [{ scale: isHovered ? 1.05 : 1 }],
                      shadowColor: blackBrand,
                      shadowOffset: { width: 5, height: 5 },
                      shadowOpacity: 0.4,
                      shadowRadius: 10,
                    }}
                  >
                    <Text
                      style={[
                        styles.infoText,
                        {
                          textTransform: 'uppercase',
                          fontSize: 24,
                          fontWeight: '700',
                          color: wineBrand,
                          textDecorationLine: 'underline',
                          textDecorationColor: redBrand,
                        },
                      ]}
                    >
                      Buy Tickets
                    </Text>
                  </View>
                )}
              </Hoverable>
            </HoverableLink>
          )}
        </View>
      </View>
    </View>
  );
};
