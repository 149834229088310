import React from 'react';
import {
  View,
  Text,
  Image,
  ImageSourcePropType,
  ScrollView,
  StyleSheet,
} from 'react-native';

import { useThemeState } from '../context/ThemeContext';
import {
  greenBrand,
  GTAmericaCompressed,
  PanamaBold,
  blackBrand,
} from '../libs/constants';
import { CuratorHeader } from '../components/curator/CuratorHeader';
import { useDimensions } from '../context/DimensionsContext';
import { mdBreakpoint, lgBreakpoint } from '../constants';
import { HoverableLink } from '../components/common/HoverableLink';
import { Hoverable } from 'react-native-web-hooks';
import { TouchableOpacity } from '../components/common/TouchableOpacity';

interface Props {}

export const ArtCafeScreen: React.FC<Props> = () => {
  const { theme } = useThemeState();
  const { width } = useDimensions();
  const artCafe = require('@happymedium/components/assets/images/artShopCurved.png');
  const measuringTape = require('@happymedium/components/assets/images/blue-white-measure-tape-long-horizontal.png');
  const styles = StyleSheet.create({
    infoText: {
      fontFamily: GTAmericaCompressed,
      fontWeight: '300',
      fontSize: 24,
    },
  });
  return (
    <ScrollView
      style={{
        flex: 1,
        // alignItems: 'center',
        // alignContent: 'center',
        // justifyContent: 'center',
        backgroundColor: greenBrand,
        paddingBottom: 100,
      }}
    >
      <CuratorHeader
        backgroundColor={greenBrand}
        hideThemePicker={true}
        logoColor={'black'}
        linkColor={blackBrand}
        isRow={true}
      />
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
          backgroundColor: greenBrand,
          flexDirection: width > lgBreakpoint ? 'row' : 'column',
        }}
      >
        <View>
          <Image
            source={
              'https://49market.s3.amazonaws.com/art+shopsAsset+9s.png' as ImageSourcePropType
            }
            style={{
              height: 410,
              width: 390,
              maxWidth: '100vw',
              marginHorizontal: 10,
              resizeMode: 'contain',
            }}
          />
        </View>
        <View style={{ alignItems: 'center' }}>
          <Image
            source={artCafe}
            style={{
              height: 80,
              width: 250,
              resizeMode: 'contain',
              marginTop: 15,
            }}
          />
          <Text
            style={{
              fontFamily: GTAmericaCompressed,
              fontSize: 24,
              fontWeight: '300',
              maxWidth: 330,
              textAlign: 'center',
              paddingVertical: 20,
              color: blackBrand,
            }}
          >
            Located in a light-filled artist’s loft in Two Bridges, the art cafe
            is designed to inspire the mind and delight the senses.
          </Text>
          <View
            style={{
              borderWidth: 2,
              borderColor: blackBrand,
              padding: 20,
              margin: 10,
              alignItems: 'center',
            }}
          >
            <Hoverable>
              {(isHovered) => (
                <TouchableOpacity
                  onPress={() =>
                    window.open(
                      'https://www.google.com/maps/place/49+Market+St,+New+York,+NY+10002/@40.7119241,-73.994309,15z/data=!4m13!1m7!3m6!1s0x89c25a2f568a286f:0xef43c1aa02ff27f1!2s49+Market+St,+New+York,+NY+10002!3b1!8m2!3d40.7119003!4d-73.9945523!3m4!1s0x89c25a2f568a286f:0xef43c1aa02ff27f1!8m2!3d40.7119003!4d-73.9945523'
                    )
                  }
                >
                  <Text
                    style={[
                      styles.infoText,
                      { textDecorationLine: isHovered ? 'underline' : 'none' },
                    ]}
                  >
                    49 Market Street, Second Floor
                  </Text>
                </TouchableOpacity>
              )}
            </Hoverable>
            <Hoverable>
              {(isHovered) => (
                <TouchableOpacity
                  onPress={() =>
                    window.open(
                      'https://www.google.com/maps/place/49+Market+St,+New+York,+NY+10002/@40.7119241,-73.994309,15z/data=!4m13!1m7!3m6!1s0x89c25a2f568a286f:0xef43c1aa02ff27f1!2s49+Market+St,+New+York,+NY+10002!3b1!8m2!3d40.7119003!4d-73.9945523!3m4!1s0x89c25a2f568a286f:0xef43c1aa02ff27f1!8m2!3d40.7119003!4d-73.9945523'
                    )
                  }
                >
                  <Text
                    style={[
                      styles.infoText,
                      { textDecorationLine: isHovered ? 'underline' : 'none' },
                    ]}
                  >
                    New York, NY 10002
                  </Text>
                </TouchableOpacity>
              )}
            </Hoverable>
            <View style={{ height: 20 }} />
            <Text style={styles.infoText}>Wed-Fri: 5pm - 10pm</Text>
            <Text style={styles.infoText}>Sat-Sun: 10am - 10pm</Text>
          </View>
          <Image
            source={measuringTape}
            style={{
              height: 80,
              width: 450,
              maxWidth: '100vw',
              resizeMode: 'contain',
              position: width < lgBreakpoint ? 'absolute' : 'relative',
              bottom: width < lgBreakpoint ? -100 : 'auto',
              left: width < lgBreakpoint ? 'auto' : 200,
              top: width < lgBreakpoint ? 'auto' : 20,
            }}
          />
        </View>
      </View>
    </ScrollView>
  );
};
