import React, { ReactElement } from 'react';
import { ImageSourcePropType, ImageURISource } from 'react-native';

import { SalonIssueOne } from '../components/salon/issues/SaloneIssueOne';
import { SalonIssueTwo } from '../components/salon/issues/SalonIssueTwo';
import { SalonIssueThree } from '../components/salon/issues/SalonIssueThree';
import { SalonIssueFour } from '../components/salon/issues/SalonIssueFour';
import { SalonIssueFive } from '../components/salon/issues/SalonIssueFive';
import { SalonIssueSix } from '../components/salon/issues/SalonIssueSix';
import { SalonIssueSeven } from '../components/salon/issues/SalonIssueSeven';
import { SalonIssueEight } from '../components/salon/issues/SalonIssueEight';
import { SalonIssueNine } from '../components/salon/issues/SalonIssueNine';
import { SalonIssueTen } from '../components/salon/issues/SalonIssueTen';
import { SalonIssueEleven } from '../components/salon/issues/SalonIssueEleven';
import { FrickByEdith } from '../components/salon/issues/FrickByEdith';

export type IssuePreview = {
  issueNumber: number;
  publishedDate: string;
  previewImageUrl: ImageSourcePropType;
  title: string;
  lede: string;
};

export const issueOne: IssuePreview = {
  issueNumber: 1,
  publishedDate: '11/6/2020',
  previewImageUrl: 'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/001/Image+1.jpg' as ImageURISource,
  title: 'Welcome to the Salon',
  lede: 'Gertrude Stein, Matisse Cut Outs, and the Birth of Modern Art',
};
export const issueTwo: IssuePreview = {
  issueNumber: 2,
  publishedDate: '11/13/2020',
  previewImageUrl: 'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/002/Image+3.jpg' as ImageURISource,
  title: 'Send Nudes',
  lede: 'Édouard Manet, historic nudes, and #freethenipple',
};

export const issueThree: IssuePreview = {
  issueNumber: 3,
  publishedDate: '11/20/2020',
  previewImageUrl: 'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/003/Img+6+resize.jpg' as ImageURISource,
  title: 'The Queen’s $13B art stash',
  lede: 'Lies, spies, and the world’s largest private art collection',
};
export const issueFour: IssuePreview = {
  issueNumber: 4,
  publishedDate: '11/27/2020',
  previewImageUrl: 'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/004/1+-+resize.jpg' as ImageURISource,
  title: 'Hidden Gems',
  lede: 'Stories of hidden gems and flea market finds',
};
export const issueFive: IssuePreview = {
  issueNumber: 5,
  publishedDate: '12/04/2020',
  previewImageUrl: 'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/005/4.-+resize.jpg' as ImageURISource,
  title: 'Art & Science',
  lede: 'Jean Metzinger, Cubism, & Quantum Theory',
};
export const issueSix: IssuePreview = {
  issueNumber: 6,
  publishedDate: '12/11/2020',
  previewImageUrl: 'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/006/img+1.jpg' as ImageURISource,
  title: 'Metaphysics & Creativity',
  lede: 'Using Art to Tap into Universal Energy',
};
export const issueSeven: IssuePreview = {
  issueNumber: 7,
  publishedDate: '12/18/2020',
  previewImageUrl: 'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/007/movies/10m1resize.jpg' as ImageURISource,
  title: 'HM Book & Movie Recs',
  lede: 'What to read and watch over the holidays',
};
export const issueEight: IssuePreview = {
  issueNumber: 8,
  publishedDate: '01/08/2021',
  previewImageUrl: "https://thesalonnewsletter.s3.us-east-2.amazonaws.com/008/Sotheby's.jpg" as ImageURISource,
  title: 'What makes art good?',
  lede: "Hint: it's not about popularity",
};
export const issueNine: IssuePreview = {
  issueNumber: 9,
  publishedDate: '01/15/2021',
  previewImageUrl: 'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/009/Salon-009-1.png' as ImageURISource,
  title: 'Get to know Shel Silverstein',
  lede: 'Beloved author, Playboy contributor, song writer, and beautiful soul',
};
export const issueTen: IssuePreview = {
  issueNumber: 10,
  publishedDate: '01/22/2021',
  previewImageUrl: 'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/010/7resize.jpg' as ImageURISource,
  title: 'Meet M. Louise Baker',
  lede: 'Archeological illustrator, museum artist, and art teacher',
};

export const issueEleven: IssuePreview = {
  issueNumber: 11,
  publishedDate: '07/26/2021',
  previewImageUrl: 'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/011/Horizontal.jpg' as ImageURISource,
  title: 'Happy Medium IRL',
  lede:
    'We’re opening the hottest new art hang in NYC. This is the story behind how we did it and why.',
};

export const issueTwelve: IssuePreview = {
  issueNumber: 12,
  publishedDate: '08/28/2021',
  previewImageUrl: 'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/012/Group+79.png' as ImageURISource,
  title: 'Edith Young At the Frick Madison',
  lede:
    'We asked expert observer Edith Young to assess the vibe at the new Frick Madison, currently housed in the Breuer building.',
};

export const salonIssues: Record<string, ReactElement> = {
  12: <FrickByEdith />,
  11: <SalonIssueEleven />,
  10: <SalonIssueTen />,
  9: <SalonIssueNine />,
  8: <SalonIssueEight />,
  7: <SalonIssueSeven />,
  6: <SalonIssueSix />,
  5: <SalonIssueFive />,
  4: <SalonIssueFour />,
  3: <SalonIssueThree />,
  2: <SalonIssueTwo />,
  1: <SalonIssueOne />,
};

export const salonIssuePreviewData = [
  issueTwelve,
  issueEleven,
  issueTen,
  issueNine,
  issueEight,
  issueSeven,
  issueSix,
  issueFive,
  issueFour,
  issueThree,
  issueTwo,
  issueOne,
];
