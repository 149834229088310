import React, { useState } from 'react';
import { Text, StyleSheet, View, Animated } from 'react-native';

import { useThemeState } from '../../context/ThemeContext';
import { PanamaBold } from '../../libs/constants';
import { ThemedRefreshIcon } from '../common/ThemedRefreshIcon';
import { TouchableOpacity } from '../common/TouchableOpacity';
import { useEffect } from 'react';
import { useRef } from 'react';

interface Props {}

export const DrawingPrompts: React.FC<Props> = () => {
  const prompts = [
    'your soul',
    'gratitude',
    'the root cause',
    'the source of your power',
    'your aura',
    'the path before you',
    'the astral plane',
    'your purpose',
    'energy',
    'consciousness',
    'love',
    'truth',
    'awareness',
    'purity',
    'wisdom',
    'faith',
    'the center of the universe',
    'your essence',
    'a story that holds you back',
    'your inner child',
    'fulfillment',
    'the feeling of flying',
    "your mind's eye",
    'awareness',
    'the cost of fear',
    'your kryptonite',
    'the beginning',
    'a warm embrace',
    'mother nature',
    'your astrology sign',
    'sour',
    'the here and now',
  ];

  const [displayIndex, updateDisplayIndex] = useState(0);

  const [fadeAnim, setFadeAnim] = useState(new Animated.Value(0));

  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 1000,
    }).start();
  }, [fadeAnim, displayIndex]);

  const { theme } = useThemeState();

  const styles = StyleSheet.create({
    prompt: {
      fontFamily: PanamaBold,
      fontSize: 18,
      lineHeight: 30,
      letterSpacing: 0.84,
      color: theme.foregroundColor,
      opacity: (fadeAnim as unknown) as number,
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginHorizontal: 25,
      marginTop: 15,
      marginBottom: 15,
    },
    draw: {
      fontFamily: PanamaBold,
      fontSize: 28,
      letterSpacing: 1.3,
      color: theme.foregroundColor,
      textAlign: 'center',
    },
    promptContainer: {
      borderBottomWidth: 1,
      borderBottomColor: theme.foregroundColor,
      width: '60%',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
    },
  });
  return (
    <View style={styles.container}>
      <Text style={[styles.draw]}>Draw</Text>
      <View style={styles.promptContainer}>
        <Animated.Text style={styles.prompt}>
          {prompts[displayIndex]}
        </Animated.Text>
      </View>
      <TouchableOpacity
        onPress={() => {
          setFadeAnim(new Animated.Value(0));
          if (displayIndex < prompts.length - 1) {
            updateDisplayIndex(displayIndex + 1);
          } else if (displayIndex === prompts.length - 1) {
            updateDisplayIndex(0);
          }
        }}
      >
        <ThemedRefreshIcon height={18} width={20} />
      </TouchableOpacity>
    </View>
  );
};
