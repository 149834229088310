import React from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  Platform,
  Linking,
  ScrollView,
} from 'react-native';
import { Hoverable } from 'react-native-web-hooks';

import { useThemeState } from '../../context/ThemeContext';
import { GTAmerica } from '../../libs/constants';
import { TouchableOpacity } from '../common/TouchableOpacity';
import { useDimensions } from '../../context/DimensionsContext';
import { useLocation } from '../../navigation';
import { is } from 'date-fns/locale';

interface Props {
  linkColor?: string;
  isRow?: boolean;
}

export const HeaderLinks: React.FC<Props> = React.memo(
  ({ linkColor, isRow }) => {
    const { theme } = useThemeState();
    const { width } = useDimensions();
    const location = useLocation();

    const lgBreakpoint = 1180;
    const mdBreakpoint = 860;
    const smBreakpoint = 420;
    const styles = StyleSheet.create({
      container: {
        // flexDirection: width < mdBreakpoint ? 'column' : 'column',
        // paddingHorizontal: width < lgBreakpoint || isRow ? 40 : 0,
      },
      linkContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        // marginLeft: 20,
        marginRight: width < lgBreakpoint || isRow ? 20 : 0,
      },
      arrow: {
        height: 9,
        width: 9,
        marginLeft: 5,
      },
      linkLabel: {
        fontFamily: GTAmerica,
        fontWeight: '500',
        fontSize: width < mdBreakpoint ? 16 : 18,
        lineHeight: width < mdBreakpoint ? 21 : 25,
        letterSpacing: 0.85,
        textTransform: 'capitalize',
        // marginRight: width < lgBreakpoint || isRow ? 15 : 0,
      },
    });

    const goArrow = require(`@happymedium/components/assets/images/arrow-up-right-${
      theme.logoColor
    }@1x.png`);
    const links = [
      {
        title: 'Art Café',
        url: '/art-cafe',
        type: 'internal',
        desktopOnly: false,
      },
      {
        title: 'Events',
        url: '/events',
        type: 'internal',
        desktopOnly: false,
      },
      {
        title: 'the salon',
        url: '/the-salon',
        type: 'internal',
        desktopOnly: false,
      },
      {
        title: 'gallery',
        url: '/gallery',
        type: 'internal',
        desktopOnly: true,
      },
      {
        title: 'about',
        url: '/about',
        type: 'internal',
        desktopOnly: false,
      },
      {
        title: 'instagram',
        url: 'https://www.instagram.com/gethappymedium/',
        type: 'web',
        desktopOnly: false,
      },
    ];

    return (
      <View style={{ marginHorizontal: 10 }}>
        <ScrollView
          // contentContainerStyle={{ marginHorizontal: 40 }}
          scrollsToTop={true}
          style={[
            styles.container,
            {
              height: width < lgBreakpoint || isRow ? 40 : 'auto',
              flexGrow: 0,
              maxWidth: '90vw',
            },
          ]}
          horizontal={width < lgBreakpoint || isRow ? true : false}
          showsHorizontalScrollIndicator={false}
          alwaysBounceHorizontal={false}
          // alwaysBounceVertical={true}
        >
          {links.map((link) => (
            <View
              key={link.title}
              style={[
                // styles.linkContainer,
                {
                  display:
                    link.desktopOnly && width < smBreakpoint ? 'none' : 'flex',
                },
              ]}
            >
              <Hoverable>
                {(isHovered) => (
                  <TouchableOpacity
                    style={styles.linkContainer}
                    onPress={() => {
                      if (Platform.OS === 'web') {
                        if (link.type === 'web') {
                          window.open(link.url, '__blank');
                        } else if (
                          link.type === 'mail' ||
                          link.type === 'internal'
                        ) {
                          Linking.openURL(link.url);
                        }
                      } else {
                        if (link.url) {
                          Linking.openURL(link.url);
                        }
                      }
                    }}
                  >
                    <Text
                      style={[
                        styles.linkLabel,
                        {
                          color: linkColor ? linkColor : theme.foregroundColor,
                          textDecorationLine:
                            isHovered || location.pathname === link.url
                              ? 'underline'
                              : 'none',
                        },
                      ]}
                    >
                      {link.title}
                    </Text>
                    {link.type !== 'internal' && (
                      <Image style={styles.arrow} source={goArrow} />
                    )}
                  </TouchableOpacity>
                )}
              </Hoverable>
            </View>
          ))}
        </ScrollView>
      </View>
    );
  }
);
