import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { split } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';

const env = process.env.NODE_ENV;
const reactEnv = process.env.REACT_APP_STAGING;

let uri;
if (env === 'development') {
  console.log('__DEV__');
  uri = 'http://192.168.1.4:4000/graphql';
} else if (reactEnv === 'staging') {
  console.log('__STAGING__');
  uri = 'https://happy-medium-staging.herokuapp.com/graphql';
} else if (env === 'production') {
  console.log('__PROD__');
  uri = 'https://happy-medium-server.herokuapp.com/graphql';
} else {
  uri = 'http://192.168.1.4:4000/graphql';
}
// const uri = process.env.SERVER_URI;
const cache = new InMemoryCache();

const SERVER_LINK_OPTIONS = {
  uri,
  credentials: 'include',
};

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
    },
  };
});

const httpLink = createUploadLink(SERVER_LINK_OPTIONS);

const link = authLink.concat(httpLink);

export const client = new ApolloClient({
  link,
  cache,
});
