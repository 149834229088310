export const creamedEgg = '#FBF7B6';
export const daffodil = '#F7EE6E';
export const justBlue = '#003366';
export const emerald = '#1F4730';
export const mint = '#A8D8BB';
export const blackCherry = '#2F1110';
export const cherry = '#990000';
export const cajunShrimp = '#FF0000';
export const petal = '#FFCCCC';
export const oatmeal = '#D0B495';
export const flax = '#F3EBE2';

export const greenBrand = '#008965';
export const whiteBrand = '#FFFCF9';
export const blueBrand = '#064FAC';
export const redBrand = '#FD5F25';
export const wineBrand = '#833D39';
export const blackBrand = '#231F20';

export const defaultTheme = greenBrand;

export const mdbreakpoint = 767;

export const GrandSlangBSide = 'GrandSlang-B-Side';
export const GrandSlangItalic = 'GrandSlang-Italic';
export const LowdragNarrow = 'Lowdrag-Narrow';
export const LowdragOutLineExtended = 'LowdragOutline-Extended';
export const LowdragOutLineNarrow = 'LowdragOutline-Narrow';
export const Reforma1918Negra = 'Reforma1918-Negra';
export const Reforma1969Gris = 'Reforma1969-Gris';
export const Reforma1969NegraItalica = 'Reforma1969-NegraItalica';
export const PanamaBold = 'Panama-Bold';
export const TrailersDemiBold = 'TTTrailers-DemiBold';
export const GTAmericaCompressed = 'GTAmerica-Compressed';
export const GTAmerica = 'GTAmerica';
export const Vorsik = 'Vorsik-Regular';
export const Pistilli = 'Pistilli';
export const Cochin = 'Cochin';
export const Minion = 'MinionPro';
