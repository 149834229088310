import React from 'react';
import { Hoverable } from 'react-native-web-hooks';
import { Text, Linking, StyleSheet, StyleProp, View } from 'react-native';

import { TouchableOpacity } from './TouchableOpacity';
import { Platform } from '../../libs/platform';
import { useDimensions } from '../../context/DimensionsContext';
import { smBreakpoint, mdBreakpoint } from '../../constants';
import { standardStyles } from '../../styles/standard';

interface Props {
  children: React.ReactNode;
  linkUrl: string;
  linkType?: 'web' | 'mail' | 'internal';
  disabled?: boolean;
  textStyle?: StyleProp<any>;
}

export const HoverableLink: React.FC<Props> = ({
  children,
  linkType,
  linkUrl,
  disabled = false,
  textStyle,
}) => {
  const { width } = useDimensions();
  const styles = StyleSheet.create({
    bodyText: {
      fontSize: width < smBreakpoint ? 14 : width < mdBreakpoint ? 15 : 16,
    },
  });
  return (
    <View style={{ flex: 1 }}>
      <Hoverable>
        {(isHovered) => (
          <TouchableOpacity
            disabled={disabled}
            accessibilityLabel={linkUrl}
            onPress={() => {
              if (Platform.OS === 'web') {
                if (linkType === 'web') {
                  window.open(linkUrl, '__blank');
                } else if (linkType === 'mail' || linkType === 'internal') {
                  Linking.openURL(linkUrl);
                }
              } else {
                if (linkUrl) {
                  Linking.openURL(linkUrl);
                }
              }
            }}
          >
            <Text
              style={[
                standardStyles.text,
                standardStyles.body,
                styles.bodyText,
                {
                  textDecorationLine: disabled ? 'none' : 'underline',
                  fontWeight: disabled ? '400' : isHovered ? '600' : '400',
                  margin: 0,
                },
                textStyle,
              ]}
            >
              {children}
            </Text>
          </TouchableOpacity>
        )}
      </Hoverable>
    </View>
  );
};
