import React from 'react';

import { Router, Switch, Route, Redirect } from '../navigation/index';
import { UnsubscribeScreen } from '../screens/Unsubscribe';
import { NewsletterSignupScreen } from '../screens/NewsletterSignupScreen';
import { CuratorScreen } from '../screens/Curator';
import { GalleryScreen } from '../screens/GalleryScreen';
import { JoinTheSalonScreen } from '../screens/JoinTheSalon';
import { SalonIssueScreen } from '../screens/SalonIssue';
import { TheSalonScreen } from '../screens/TheSalonScreen';
import { AboutScreen } from '../screens/AboutScreen';
import { ArtCafeScreen } from '../screens/ArtCafeScreen';
import { EventsLandingScreen } from '../screens/EventsLandingScreen';
import { EventOne } from '../screens/EventOne';

export function Routes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={CuratorScreen} />
        <Route exact path="/gallery" component={GalleryScreen} />
        <Route exact path="/about" component={AboutScreen} />
        <Route exact path="/art-cafe" component={ArtCafeScreen} />
        <Route exact path="/events" component={EventsLandingScreen} />
        <Route exact path="/event/1" component={EventOne} />
        <Route
          exact
          path="/join-the-salon/:email?"
          component={JoinTheSalonScreen}
        />
        <Route exact path="/the-salon" component={TheSalonScreen} />
        <Route exact path="/unsubscribe/:email" component={UnsubscribeScreen} />
        <Route
          exact
          path="/the-salon/:issueNumber/:filter?"
          component={SalonIssueScreen}
        />
        <Route
          exact
          path="/subscribe/:email?"
          component={NewsletterSignupScreen}
        />
        {/* skip splash screen if query param === 'vip' */}
        <Route exact path="/:vip?" component={CuratorScreen} />
        <Redirect from="*" to="/" />
      </Switch>
    </Router>
  );
}
